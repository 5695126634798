// @font-face {
//   font-family: "Conv_NoveoSans-Bold";
//   src: url("NoveoSans-Bold.eot");
//   src: local("☺"), url("NoveoSans-Bold.woff") format("woff"),
//     url("NoveoSans-Bold.ttf") format("truetype"),
//     url("NoveoSans-Bold.svg") format("svg");
//   font-weight: normal;
//   font-style: normal;
// }

.mainLogo {
  transition: all .3s ease-in-out;
  flex-shrink: 0;
  margin: 0;
  top: 10px;
  &:hover {
    transform: scale(1.05);
  }
  @media (max-width: $nav-breakpoint) {
    width: $logo-width * .85;
    height: $logo-height * .85;
  }
  @media (max-width: map-get($breakpoints, md)) {
    width: $logo-width * 0.75;
    height: $logo-height * 0.75;
  }
}

.pageHeader .headerContent > *:not(.mainLogo) {
  margin: 0; /* overwrite fresco */
}

.socialIcons {
  li {
    transition: all .3s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }
}

.pageHeader {
  .headerContent {
    .header-search {
      order: 2;
      flex-grow: 1;
    }
    .mainCallToAction {
      order: 3;
      @media (min-width: $nav-breakpoint) {
        display: flex;
        align-items: flex-end;
        height: 0;
      }
      .cta-button {
        margin-top: 0; /* overwrite fresco */
        margin-bottom: 0; /* overwrite fresco */
      }
    }
    .mainCallToActionButtons {
      display: flex;
      align-items: flex-end;
      margin: 8px 0 0 20px;
      .cta-button:not(.cta-subsite) {
        display: none;
      }
    }
    .tagline {
      order: 4;
      width: 200px;
      flex-grow: 0;
      height: 0;
      img {
        width: 200px;
        margin-top: 20px;
      }
    }
    .socialIcons {
      //display: none;
      position: absolute;
      right: 20px;
      top: 120px;
      transition: all .3s ease-in-out;
    }
    &.sticky {
      // .tagline {
      //   top: 10px;
      // }
      // .mainCallToAction {
      //   top: 5px;
      // }
    }
  }
}

@media (max-width: $nav-breakpoint) {
  .pageHeader .headerContent {
    padding-top: 20px;
    padding-bottom: 20px;
    .mainLogo {
      position: absolute;
      z-index: 3;
    }
    .tagline img {
      margin-top: -30px;
      padding-left: 20px;
    }
    .mainCallToAction {
      align-items: flex-end;
      .mainCallToActionButtons {
        margin: 0px 10px 0 20px;
        @media (max-width: $cta-btn-breakpoint) {
          margin: 0 5px;
        }
      }
    }
  }
  .menuMainAlt {
    padding: 10px 20px;
    font-size: 0.875em;
  }
  .menuMain ul.topLevel {
    padding-top: 35px;
    padding-bottom: 15px;
  }
}

/* Styled menu buttons */

li#menudb4fd935-19fd-4baf-b245-762d0cf2c8f1,
li#menu2a57d377-e320-4b49-9d9f-936e1f9ecb20,
li#menu43ff97b4-b5ef-40f8-8f71-e0b9edf3d91b,
li#menua4b3fcbd-bfe6-4a30-acb0-f05597ed5d43,
li#menud438e8b2-3428-4982-b109-590a67b12014,
li#menud2794da0-2a2f-4fec-8b22-4121a28289b4,
li#menu9d458b6c-01c4-41e6-abfa-0dcf72422054,
li#menueb6911f3-a628-4668-8e24-bbcdb258da5f,
li#menu6b07dbd2-2cff-4037-ae5d-7c4f50549552,
li#menu38f92638-2552-4396-b589-345cc9680c57 {
  > a {
    background: $black;
    color: white;
    border-radius: 25px;
    margin: 0 10px;
    padding: 10px 20px;
    transition: all .3s ease-in-out;
    @media (min-width: $nav-breakpoint) {
      &:hover {
        transform: scale(1.1);
      }
    } 
    @media (max-width: $nav-breakpoint) {
      margin: 5px 10px;
    }
  }
}

li#menu2a57d377-e320-4b49-9d9f-936e1f9ecb20,
li#menu43ff97b4-b5ef-40f8-8f71-e0b9edf3d91b {
  > a {
    background: $yellow;
    color: $black;
  }
}

/* End styled menu buttons */

body.homepage {
  .carouselWrapper {
    background-color: $brand-red;
    width: 100%;
    position: relative;
  }
  .carouselSlideTitle {
    margin: 50px 0 50px 20px;
    @media (max-width: $carousel-breakpoint) {
      margin: 20px 0 20px 20px;
    }
    position: relative;
    img {
      transform: scale(75%);
      z-index: 1;
    }
    &:before {
      content: '';
      position: absolute;
      background-image: url($assets-path + 'carousel-mask.svg');
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }
  @media (max-width: $carousel-breakpoint) {
    .carouselControls {
      //align-items: flex-start;
      display: none;
    }
    .carousel {
      padding-bottom: 3em;
    }
  }
}

@media (min-width: $carousel-breakpoint) {

  body.homepage:not(.subsite) {
    .carouselWrapper {
        &:before {
        content: '';
        position: absolute;
        background-image: url($assets-path + 'weave-element-red-bg.svg');
        background-repeat: no-repeat;
        //background-size: auto 85%;
        width: 700px;
        height: 700px;
        background-position: -400px -400px;
        opacity: .25;
      }
    }
  }

}

body:not(.homepage) {
  .carousel {
    max-width: 100%;
    padding: 0;
  }
}

.homeIntroWrapper {
  margin-bottom: 0;
}
.homeIntro {
  position: relative;
  display: flex;
  align-items: center;
  h3 {
    font-size: 2.25em;
    max-width: 800px;
    margin-bottom: 0;
    flex: 1;
    padding-left: 30px;
  }
  &:before {
    content: "";
    display: block;
    width: 151px;
    height: 200px;
    background-image: url($assets-path + 'earth-element-header.png');
    background-size: 151px 200px;
    background-repeat: no-repeat;
  }
}

.homeFeaturesWrapper {
//  display: none;
}

.homeFeatures .homeFeature {
  z-index: 2;
}

.homeFeedsWrapper:not(.subsiteFeedsWrapper) {
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    background-image: url($assets-path + 'weave-element.svg');
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-position: bottom -400px right -400px;
    background-size: 700px;
    opacity: .25;
  }
}

.homeFeed:not(.subsiteFeed) .feedsTitle {
  padding: 35px 0 35px 125px;
  &:before {
    content: "";
    display: block;
    margin: 0;
    width: 80px;
    height: 105px;
    background-image: url($assets-path + 'earth-element-header.png');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 20px;
  }
}

.homefeaturecategory-homeboximpactstats {
  .homeImpactWrapper{
    table {
      tbody {
        align-items: center;
        justify-content: center;
      }
      tr {
        position: relative;
        height: 350px!important;
        min-width: 350px!important;
        max-width: 350px!important;
        &:before {
          content: '';
          display: block;
          width: 350px;
          height: 350px;
          background-image: url($assets-path + 'weave-element-impact-red-bg.svg');
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
        }
        td {
          max-width: 200px;
        }
      }
      @media (max-width: map-get($breakpoints, sm)) {
        font-size: .75em;
        tr {
          height: 250px!important;
          min-width: 250px!important;
          max-width: 250px!important;
          &:before {
            content: '';
            display: block;
            width: 250px;
            height: 250px;
            background-image: url($assets-path + 'weave-element-impact-red-bg.svg');
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
          }
          td {
            max-width: 150px;
          }
        }
      }
    }
  }
}

.footerBox1 {
  padding-right: 80px;
  z-index: 1;
}

.Newsletter {
  label {
    color: white;
  }
}

[class*=ewsletter].footerBox input {
  z-index: 1;
}

/* listing carousel */

.listing {
  .carousel {
    //display: none; /* hide for now until styled */
  }
  .carouselDetailWrapper {
    background-color: $brand-primary;
    padding: 20px;
  }
}

// DES-3675 - JK - Footer social tweaks
// Candidate for new variables
.footerBox .socialIcons {
  margin-bottom: 10px;

  a {
    width: 40px;
    height: 50px;
    border-radius: 0;
    justify-content: flex-start;

    &:before {
      font-size: 1.75rem;
    }
  }
}



//video full width of the banner

.noBanner .carouselSlide.containsVideo {
  max-width: 100%;
}

// Page - Container width embedded video 
.postVideoObjectEmbed.embed-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.45%;

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}



// Mailchimp setup
.footerBox[class*='ewsletter'] #mc_embed_signup_scroll {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  h2 {
    font-size: $font-size-h5;
  }

  .indicates-required {
    font-size: .75em;
    position: absolute;
    top: 2.5px;
    right: 15px;
    color: rgba($white, 0.85);
  }

  >.mc-field-group {
    display: flex;
    flex-direction: column;
    margin: 0 15px 10px 0;

    &.eMail {
      width: 100%;
    }

    &.lName,
    &.fName {
      width: calc(50% - 15px);

      @media (max-width: map-get($breakpoints, sm)) {
        width: 100%;
      }
    }

    >label {
      margin: 0;
      color: rgba($white, 0.85);
      font-size: $font-size-small;
    }

    @media (max-width: map-get($breakpoints, sm)) {
      width: 100%;
      margin: 0 0 10px 0;
    }
  }

  >.mc-field-group.gdpr-mergeRow {
    font-size: $font-size-small;

    p {
      margin-bottom: 0.25em;
    }
  }

  fieldset.gdprRequired {
    display: flex;

    .av-checkbox {
      margin-right: 10px;
      margin-left: 10px;
    }
  }

  input.cta-button {
    margin-top: 10px;
    background-color: $yellow;
    color: text-contrast($yellow) !important;

    &:hover {
      background-color: darken($yellow, 10%);
    }
  }
}

@media (max-width: map-get($breakpoints, md)) {
  .footerBox1 {
    // display: block;
    // width: 100%;
    padding-right: 0;
  }
}


// Subsites
body.subsite {
  @mixin subsite-colour ($subsite-colour-1, $subsite-feeds-title-colour) {
    .pageHeader .headerContent {
      max-width: 1380px;
      .socialIcons {
        display: flex;
      }
    }

    .mainCallToAction {
      // Only subsite ctas in header
      .cta-button:not(.cta-subsite) {
        display: none;
      }

      // Only facebook icon in header
      ul.socialIcons {
        li {
          margin: 0;
        }
        a {
          background-color: $subsite-colour-1;
          &:before {
            color: white;
          }
        }
        a:not(.facebook) {
          display: none;
        }
      }

    }

    .menuMain .mainCallToAction .socialIcons {
      display: none; /* Hide social icons in subsite mobile nav */
    }

    .cta-back-to-main-site {
      background-color: transparent;
      border-radius: 0;
      background-image: url($assets-path + 'proudly-alpa.png');
      @media (max-width: $cta-btn-breakpoint) {
        background-image: url($assets-path + 'proudly-alpa-white.png');
      }
      background-size: contain;
      width: 200px;
      height: 62px;
      background-repeat: no-repeat;
      transition: all .3s ease-in-out;
      &:hover {
        background-color: transparent;
        transform: scale(1.1);
      }
    }

    // Remove subsite menu
    .menuSub {
      display: none;
    }

    // Nav + Burger - Background
    .menuMainAlt {
      background-color: $subsite-colour-1;
    }

    // Nav - inner
    @media (min-width: $nav-breakpoint) {
      .menuMain .subMenu a,
      .subMenu .hasSubmenu>a:after {
        color: $subsite-colour-1;
      }
    }
  
    @media (max-width: $nav-breakpoint) {
      .menuMain {
        background-color: $subsite-colour-1;
      }

    }

    // Re-order items
    .contentContainer {
      .headerWrapperSubsite {
        order: 1;
      }

      .headerTextSubsite {
        order: 2;
      }

      .subsiteBody {
        order: 3;
      }

      .subsiteFeedsWrapper {
        order: 4;
      }
    }

    .carouselWrapper {
      background-color: $subsite-colour-1;
    }

    .carouselSlide {
      align-items: center;
    }

    .carouselSlideTitle {
      &:before {
        content: none;
      }
      img {
        transform: none;
        border-radius: 50%;
      }
    }

    // Subsite Body - layout
    .subsiteBody {
      padding-top: 0;
    }

    // Subsite Title
    .headerTextSubsite {
      padding: 20px 0 0;
      //max-width: 450px;
      .subsiteTitle {
        color: text-contrast($subsite-colour-1);
      }
      .subsiteBody {
        color: text-contrast($subsite-colour-1);
        display: block;
        margin: 20px 0;
        padding: 0;
      }
    }

    // Feeds - Layout
    .subsiteFeed {
      flex: unset;
      margin: 2rem 0;
    }

    .subsiteFeed {
      .feedItem {
        @include card(
          $card-heading-colour: $body-colour,
          $card-hover-heading-colour: $body-colour,
          $card-details-background-colour: transparent,
          $card-hover-details-background-colour: transparent
        );
      }
    }

    .subsiteFeed {
      .feedsTitle {
        color: $subsite-feeds-title-colour;
      }
    }


    // Footer - Background
    .pageFooterWrapper:before {
      display: none;
    }

    // .pageFooterWrapper {
    //   background-color: $subsite-colour-1;
    // }

    // Footer - Background + Layouts
    .pageFooter {
      display: flex;
      flex-wrap: wrap;
      max-width: $container-max-width;
      margin: 0 auto;

      .footerBox *:before,
      .footerBox * {
        //color: text-contrast($subsite-colour-1);
      }

      .footerBox[class*='ewsletter'] {
        display: none;
      }

      @media (min-width: map-get($breakpoints, md) + 1px) {
        .footerBox {
          width: calc(50% - 30px);
        }
      }
    }

    // Inner pages - overall
    &.subsiteInner {

      // Remove subsite tile
      .headerTextSubsite {
        display: none;
      }

      // Remove sidebars
      .contentBlockWrapper .contentBlock {
        display: block;

        .postAside {
          display: none;
        }
      }

      // Inner content headings
      .postContent h2,
      .postContent h2 a,
      .postContent h3,
      .postContent h3 a,
      .postContent h4,
      .postContent h4 a,
      .postContent h5,
      .postContent h5 a,
      .postContent h6,
      .postContent h6 a {
        color: $subsite-colour-1;
      }

      // CTA Buttons
      .postContent .cta-button {
        background-color: $subsite-colour-1;

        &:hover {
          background-color: darken($subsite-colour-1, 10%);
        }
      }

      blockquote {
        background-color: $subsite-colour-1;
      }

    }

  }

  // Subsite - colour specific
  &.rulku {
    @include subsite-colour($subsite-colour-1: #69914E,
      $subsite-feeds-title-colour: #69914E);

    a.mainLogo {
      background-image: url($assets-path + 'rulku-logo.png');
      width: 150px;
      height: 150px;
      background-color: white;
      border-radius: 125px;
      box-shadow: 0 0 10px rgba($black, 0.1);
      @media (max-width: $nav-breakpoint) {
        width: 110px;
        height: 110px;
        border-radius: 110px;
      }
      @media (max-width: $cta-btn-breakpoint) { /* when proudly Alpa moves in to mobile nav and header area shrinks) */
        width: 85px;
        height: 85px;
        border-radius: 85px;
      }
    }

    .carouselSlide img {
     border: 8px solid black;
    }

    .carouselWrapper {
      overflow: hidden;
      background: linear-gradient(90deg, rgba(220,61,49,1) -30%, rgba(247,233,74,1) 99%);
      .carouselSlideTitle {
        &:before {
          content:'';
          background-image: url($assets-path + 'rulku-barramundi-green.svg');
          background-repeat: no-repeat;
          width: 300px;
          height: 186px;
          position: absolute;
          bottom: 0;
          right: 350px;
          background-size: contain;
          background-position: bottom;
          opacity: 1;
          z-index: 2;
          transform: scaleX(-1);
          @media (max-width: $carousel-breakpoint) {
            right: 0;
            bottom: -20px;
          }
          @media (max-width: map-get($breakpoints, sm)) {
            width: 150px;
            height: 93px;
            bottom: 0;
            right: 0;
          }
        }
      // &:after {
      //   content:'';
      //   background-image: url($assets-path + 'rulku-bg.svg');
      //   background-repeat: no-repeat;
      //   width: 1300px;
      //   height: 100%;
      //   position: absolute;
      //   bottom: 20px;
      //   left: -1000px;
      //   background-size: contain;
      //   background-position: bottom;
      //   opacity: 1;
      //   z-index: 50;
      // }
      }
    }

    li#menud2794da0-2a2f-4fec-8b22-4121a28289b4,
    li#menu38f92638-2552-4396-b589-345cc9680c57 {
      > a {
        background-color: #a1c742;
        color: inherit;
      }
    }

    .subsiteFeedsWrapper {
        position: relative;
        max-width: $container-max-width;
        margin: auto;
        &:before {
          content: '';
          position: absolute;
          width: 400px;
          height: 250px;
          background-image: url($assets-path + 'rulku-map-red.png');
          background-repeat: no-repeat;
          background-size: 250px;
          top: 20px;
          left: -175px;
        }
        &:after {
          content: '';
          position: absolute;
          width: 536px;
          height: 118px;
          background-image: url($assets-path + 'rulku-bg-30.svg');
          background-repeat: no-repeat;
          background-position: bottom;
          bottom: 0;
          right: -150px;
        }
    }
    
  }

  &.island-and-cape {
    @include subsite-colour($subsite-colour-1: #0098b6,
      $subsite-feeds-title-colour: #0098b6);

    a.mainLogo {
      background-image: url($assets-path + 'islandcape-logo-roundel-shadow.png');
      width: 175px;
      height: 159px;
      @media (max-width: $nav-breakpoint) {
        width: 150px;
        height: 136px;
      }
      @media (max-width: $cta-btn-breakpoint) { /* when proudly Alpa moves in to mobile nav and header area shrinks) */
        width: 100px;
        height: 91px;
      }
    }

    .headerTextSubsite {
      .subsiteTitle {
        color: $grey;
      }
      .subsiteBody {
        color: $grey;
      }
    }

    li#menu9d458b6c-01c4-41e6-abfa-0dcf72422054,
    li#menueb6911f3-a628-4668-8e24-bbcdb258da5f {
      > a {
        background-color: #f7ea5c;
        color: inherit;
      }
    }

    .cta-back-to-main-site {
      margin-left: 0;
    }

    .carouselSlide img {
      border: 8px solid #f5ea01;
    }

    .carouselWrapper {
      overflow: hidden;
      &:before {
        content:'';
        background-image: url($assets-path + 'island-and-cape-carousel.png');
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background-size: 100% 100%;
        background-position: top;
        opacity: 1;
      }
      @media (max-width: $carousel-breakpoint) {
        .carousel {
          padding-left: 0;
          padding-right: 0;
        }
        background-color: white;
        &:before {
          content: none;
        }
        .carouselSlideTitle {
            background-image: url($assets-path + 'island-and-cape-carousel.png');
            background-repeat: no-repeat;
            background-size: auto 100%;
            background-position: bottom;
            margin: 0;
            padding: 20px;
        }
      }
    }

    @media (max-width: $carousel-breakpoint) {
      .headerTextSubsite {
        padding: 0 20px;
      }
    } 

    .subsiteFeedsWrapper {
      position: relative;
      max-width: $container-max-width;
      margin: auto;
      &:before {
        content: '';
        position: absolute;
        width: 226px;
        height: 218px;
        background-image: url($assets-path + 'turtle-blue.svg');
        background-repeat: no-repeat;
        background-size: contain;
        top: -60px;
        left: -100px;
        opacity: .5;
        @media (max-width: $carousel-breakpoint) {
          left: auto;
          top: -100px;
          right: 20px;
          opacity: .25;
        }
      }
      &:after {
        content: '';
        position: absolute;
        width: 133px;
        height: 109px;
        background-image: url($assets-path + 'turtle-red.svg');
        background-repeat: no-repeat;
        background-position: bottom;
        bottom: 30px;
        right: 20px;
        background-size: contain;
        transform: scaleX(-1);
        opacity: .5;
        @media (max-width: $carousel-breakpoint) {
          opacity: .25;
        }
      }
    }

    .subsiteFeed {
      .feedsTitle {
        color: #0098b6;
      }
    }


  }

  &.bukmak {
    @include subsite-colour($subsite-colour-1: #1d1f48,
      $subsite-feeds-title-colour: #1d1f48);

    a.mainLogo {
      background-image: url($assets-path + 'bukmak-logo-new.png');
      width: 250px;
      height: 77px;
      margin-top: 7px;
      @media (max-width: $cta-btn-breakpoint) { /* when proudly Alpa moves in to mobile nav and header area shrinks) */
        width: 165px;
        height: 51px;
      }
    }

    li#menud438e8b2-3428-4982-b109-590a67b12014 > a {
      background-color: #e89619;
      color: inherit;
    }

    .carouselWrapper {
      overflow-y: visible;
      overflow-x: clip;
      background-color: $grey-lightest;
      &:before {
        content:'';
        background-image: url($assets-path + 'bukmak-bg.svg');
        background-repeat: no-repeat;
        width: 1420px;
        height: 500px;
        position: absolute;
        left: -90px;
        background-size: contain;
        opacity: .25;
        top: 20px;
      }
      &:after {
        content:'';
        background-image: url($assets-path + 'dugong.svg');
        background-repeat: no-repeat;
        width: 800px;
        height: 300px;
        position: absolute;
        right: 100px;
        bottom: -150px;
        background-size: contain;
        opacity: .25;
        transform: scaleX(-1);
        @media (max-width: $carousel-breakpoint) {
          right: auto;
          left: 300px;
        }
      }
    }

    .headerTextSubsite {
      .subsiteTitle {
        color: $body-colour;
      }
      .subsiteBody {
        color: $body-colour;
      }
    }
    .carouselSlide img {
      border: 8px solid #e89619;
    }

    .subsiteFeedsWrapper {
     //background-color: $grey-lightest;
     position: relative;
    //  max-width: $container-max-width;
    //  margin: auto;
      &:before {
        content:'';
        background-image: url($assets-path + 'bukmak-bg.svg');
        background-repeat: no-repeat;
        width: 446px;
        height: 600px;
        position: absolute;
        right: 0;
        background-size: contain;
        opacity: 1;
        top: 750px;
        transform: scaleX(-1);
      }
    }

    .subsiteFeed {
      .feedItem {
        @include card(
          $card-details-background-colour: #1d1f48,
          $card-hover-details-background-colour: #1d1f48,          
          $card-heading-colour: white,
          $card-hover-heading-colour: white,
          $card-summary-colour: white,
          $card-hover-summary-colour: white
        );
      }
    }
    
  }

  &.dinybulu {
    @include subsite-colour($subsite-colour-1: #17ABDD,
      $subsite-feeds-title-colour: #259ad5);

    a.mainLogo {
      background-image: url($assets-path + 'dinybulu-logo.png');
      width: 150px;
      height: 150px;
      border: 10px solid white;
      background-color: white;
      border-radius: 150px;
      box-shadow: 0 0 10px rgba($black, 0.1);
      @media (max-width: $nav-breakpoint) {
        width: 110px;
        height: 110px;
        border-radius: 110px;
      }
      @media (max-width: $cta-btn-breakpoint) { /* when proudly Alpa moves in to mobile nav and header area shrinks) */
        width: 85px;
        height: 85px;
        border-radius: 85px;
      }
    }

    .carouselWrapper {
      background: linear-gradient(180deg, rgba(230,137,34,1) 0%, rgba(248,230,0,1) 99%);
      overflow: hidden;
      &:before {
        content:'';
        background-image: url($assets-path + 'sun.svg');
        background-repeat: no-repeat;
        width: 1000px;
        height: 1000px;
        position: absolute;
        bottom: -350px;
        left: -250px;
        opacity: .2;
      }
      
    }

    li#menua4b3fcbd-bfe6-4a30-acb0-f05597ed5d43,
    li#menu6b07dbd2-2cff-4037-ae5d-7c4f50549552 {
      > a {
        background: #f8e600;
        color: inherit;
      }
    }

    .carouselSlide img {
      border: 8px solid #dadbd9;
    }

    .headerTextSubsite {
      .subsiteBody, .subsiteTitle {
        color: $black;
      }
    }

    .feedItem:hover .feedItemDetailsWrapper {
      //background: linear-gradient(180deg, #2984c5 0%, rgba(16,6,159,1) 75%)!important;
    }
  }
}

body:not(.subsite) .mainCallToAction .cta-button.cta-back-to-main-site {
  display: none;
}


// Sticky sidebar on Desktop
@media (min-width: map-get($breakpoints, md) + 1px) {
  .post .contentBlock .postAside {
    position: sticky;
    top: 20px;
  }
}